export const VehicleModelConfig = {
  api: {
    // get
    index: (manufacturerId) =>
      `/dashboard/vehicle-models/?bike_manufacturer=${manufacturerId}`,
    // get
    indexData: () => '/dashboard/vehicle-models/',

    // post
    create: () => '/dashboard/vehicle-models/',
    // patch
    update: (vehicleModelId) => `/dashboard/vehicle-models/${vehicleModelId}/`,

    org: {
      // get
      index: (orgId) =>
        `/dashboard/organizations/${orgId}/organization-vehicle-models/?dropdown`,
      // patch
      update: (vehicleModelId) =>
        `/dashboard/organization-vehicle-models/${vehicleModelId}/`,
    },

    choices: {
      maxSpeedUnitChoices: () =>
        '/dashboard/vehicle-model-max-speed-unit-choices/',
      rangeUnitChoices: () => '/dashboard/vehicle-model-range-unit-choices/',
      batteryCapacityUnitChoices: () =>
        '/dashboard/vehicle-model-battery-capacity-unit-choices/',
      chargingTimeUnitChoices: () =>
        '/dashboard/vehicle-model-charging-time-unit-choices/',
      motorPowerRatingUnitChoices: () =>
        '/dashboard/vehicle-model-motor-power-rating-unit-choices/',
      weightUnitChoices: () =>
        '/dashboard/vehicle-model-net-weight-unit-choices/',
      wheelDiameterUnitChoices: () =>
        '/dashboard/vehicle-model-wheel-diameter-unit-choices/',
    },
  },
  events: {
    name: 'vehicle-model',
    // refresh-index-data
    refresh: `rid-vehicle-model`,
    // slideover-right
    sorId: 'vehicle-model',
    sorOpen: 'sor-open-vehicle-model',
    sorClose: 'sor-close-vehicle-model',
    sorToggle: 'sor-toggle-vehicle-model',
    // editing-data
    editingData: 'edit-vehicle-model-data',
    // viewing-data
    viewingData: 'view-vehicle-model-data',
  },
}
