<template>
  <slide-over-right
    :id="`${sorId}-${id}`"
    editType="standard"
    ref="slideOverRightRepair"
  >
    <loading :active.sync="isLoading" :is-full-page="false"></loading>

    <div class="flex items-center justify-start mt-5 gap-2">
      <div
        class="flex items-center justify-center cursor-pointer back-button"
        @click="goBack"
      >
        <i class="fas fa-arrow-right"></i>
      </div>
      <div class="header-title">{{ titleText }}</div>
    </div>

    <div class="flex  w-full p-3 mt-5  bg-gray-50 rounded-md ">
      <div class="w-1/3 ">{{ vehicleProfile.name }}</div>
      <div class="w-1/3 flex justify-center">
        <BatteryStatus
          :level="getBatteryLevel"
          :height="`18px`"
          :width="`35px`"
          :text-class="`text-xs text-gray-600 ml-2`"
        />
      </div>
      <div class="w-1/3 flex justify-end ">
        {{ vehicleProfile.qr_code }}
      </div>
    </div>
    <div
      class="mt-5"
      :key="`vi-${itemIndex}`"
      v-for="(item, itemIndex) in repairInspectionData"
    >
      <RepairItemCard
        :id="id"
        :rData="item"
        @change-reason="(data) => handleRepairOption(itemIndex, data)"
      />
    </div>
    <div class=" mt-6">
      <div class="card bg-gray-100 flex flex-col w-full  gap-2 p-3">
        <AppButton
          variant="primary"
          width="100%"
          @click="
            getSubpartIdsWithRepairOptionsId.length > 0 && handleConfirm()
          "
          :disabled="getSubpartIdsWithRepairOptionsId.length === 0"
        >
          {{
            $t('components.vehicleInspectionAndRepairManagement.action.confirm')
          }}
        </AppButton>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { VehicleRepairConfig } from '@/config/VehicleRepairConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import RepairItemCard from '@/composites/vehicle/shared/Repair/RepairItemCard.vue'
import { xMan } from '@/utils'
import { useEndpoints } from '@/composables'
import { SocketConfig } from '@/config/SocketConfig'
import ReconnectingWebsocket from 'reconnectingwebsocket'
export default {
  name: 'VehicleRepair',
  components: {
    SlideOverRight,
    BatteryStatus,
    RepairItemCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    vehicleProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sorId: VehicleRepairConfig.events.sorId,
      isLoading: false,
      inspectionId: null,
      repairInspectionData: [],
      inspectionStatusTimeout: null,
      takeActionFrom: null,
      repairNotNeededId: 'repair-not-needed-id',
    }
  },
  async created() {},
  mounted() {
    EventBus.$on(VehicleRepairConfig.events.editingData, (data) => {
      if (this.id === `vi-${data.repairIndex}`) {
        this.inspectionId = data.inspectionId
        this.takeActionFrom = data.takeActionFrom

        const modifiedPartData = data.parts.map((part) => {
          const modifiedSubpartData = part.sub_parts.map((subpart) => {
            const modifiedRepairOptions = subpart.available_repair_options.map(
              (rOption) => {
                return {
                  ...rOption,
                  isChecked: false,
                }
              }
            )
            modifiedRepairOptions.push({
              id: this.repairNotNeededId,
              name: 'Repair Not Needed',
              isChecked: false,
            })
            return {
              ...subpart,
              available_repair_options: modifiedRepairOptions,
              isShowOption: false,
            }
          })
          return {
            ...part,
            sub_parts: modifiedSubpartData,
          }
        })
        this.repairInspectionData = modifiedPartData
      }
    })
  },
  beforeDestroy() {
    this.removeListener()
  },
  computed: {
    titleText() {
      return this.$t(
        'components.vehicleInspectionAndRepairManagement.title.repair'
      )
    },
    isEditing: function() {
      return !!this.primaryKey
    },
    actionButtonText: function() {
      return 'Submit'
    },
    getBatteryLevel() {
      return this?.vehicleProfile?.lock?.power_level || 0
    },
    getSubpartIdsWithRepairOptionsId() {
      const subpartIdsWithRepairOptionsId = []
      this.repairInspectionData.forEach((part) => {
        part.sub_parts.forEach((subpart) => {
          const repairOptionIds = []
          subpart.available_repair_options.forEach((repairOption) => {
            if (repairOption.isChecked) {
              repairOptionIds.push(repairOption.id)
            }
          })
          if (repairOptionIds.length > 0) {
            subpartIdsWithRepairOptionsId.push({
              subpartId: subpart.id,
              repairOptionIds,
            })
          }
        })
      })
      return subpartIdsWithRepairOptionsId
    },
  },
  watch: {},
  methods: {
    closeModal() {
      dispatchEvent(new Event(VehicleRepairConfig.events.sorClose(this.id)))
    },
    goBack() {
      this.closeModal()
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrlV1 +
        SocketConfig.channels.userInspection +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.repair_submitted') return

      let inspectionId = payload.data.id
      if (!inspectionId) return
      if (this.inspectionId !== inspectionId) return

      console.log(
        `Incoming data for inspection ${inspectionId} : data -->`,
        payload.data
      )
      if ('status' in payload.data) {
        let status = payload.data.status

        if (status) {
          this.lastTask(status)
        }
      }
    },
    async handleConfirm() {
      this.startListener()
      if (this.getSubpartIdsWithRepairOptionsId.length > 0) {
        this.isLoading = true
        const requests = []
        this.getSubpartIdsWithRepairOptionsId.forEach((data) => {
          const url = useEndpoints.operationPrimer.repairAction.updateRepairOption(
            data.subpartId
          )

          const payload = new xMan({}).toFormData()

          data.repairOptionIds.forEach((rId) => {
            if (rId === this.repairNotNeededId) {
              payload.append('status', 'REPAIR_NOT_NEEDED')
            } else {
              payload.append('status', 'REPAIRED')
              payload.append('repair_option', rId)
            }
          })

          const repairReq = this.$http({
            url: url,
            method: 'PATCH',
            data: payload,
          })
          requests.push(repairReq)
        })

        await this.$http
          .all(requests)
          .then(
            this.$http.spread(() => {
              this.handleRepairSubmit()
            })
          )
          .catch((err) => {
            console.error('Error occurred!', err)
            this.$notify({
              group: 'bottomLeft',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.detail,
            })
          })
      }
    },

    toggleModal() {
      dispatchEvent(new Event(VehicleRepairConfig.events.sorToggle(this.id)))
    },

    handleRepairOption(partIndex, data) {
      // this.repairInspectionData[partIndex].sub_parts[
      //   data.subpartItemIndex
      // ].available_repair_options[data.repairOptionItemIndex].isChecked =
      //   data.value
      this.repairInspectionData[partIndex].sub_parts[
        data.subpartItemIndex
      ].available_repair_options = this.repairInspectionData[
        partIndex
      ].sub_parts[data.subpartItemIndex].available_repair_options.map(
        (item, itemIndex) => {
          if (data.repairOptionItemIndex === itemIndex) {
            return {
              ...item,
              isChecked: data.value,
            }
          } else {
            return {
              ...item,
              isChecked: false,
            }
          }
        }
      )
    },
    async handleRepairSubmit() {
      await this.$http
        .patch(
          useEndpoints.operationPrimer.repairAction.submit(this.inspectionId)
        )
        .then(() => {
          this.inspectionStatusTimeout = setTimeout(() => {
            if (this.$refs.slideOverRightRepair.show) {
              this.$http
                .get(
                  useEndpoints.operationPrimer.repairAction.status(
                    this.inspectionId
                  )
                )
                .then((res) => {
                  this.lastTask(res.data.status)
                })
                .finally(() => {
                  this.isLoading = false
                  clearTimeout(this.inspectionStatusTimeout)
                  this.inspectionStatusTimeout = null
                })
            }
          }, 10000)
        })
        .catch((err) => {
          console.error('Error occurred!', { err })
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.detail,
          })
        })
    },
    lastTask(status) {
      this.removeListener()
      if (status === 'REPAIR_BACKLOG') {
        this.$notify(
          {
            group: 'bottomRight',
            type: 'success',
            title: `Submitted for ${this.vehicleProfile.name}`,
            text: `Successful and still have backlog task to repair.`,
          },
          5000
        )
      }
      if (status === 'RE_INSPECTION') {
        this.$notify(
          {
            group: 'bottomRight',
            type: 'success',
            title: `Submitted for ${this.vehicleProfile.name}`,
            text: `Successful and now you can start inspection.`,
          },
          5000
        )
      }
      this.isLoading = false
      this.closeModal()
      clearTimeout(this.inspectionStatusTimeout)
      this.inspectionStatusTimeout = null
      if (this.takeActionFrom === 'vehicle-list') {
        this.$store.dispatch('fsTable/fetchData')
      }
      if (this.takeActionFrom === 'vehicle-details') {
        this.$store.dispatch('vehicleDetails/fetchData', {
          type: 'details',
          primaryKey: `${this.vehicleProfile.id}`,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
