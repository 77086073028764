<template>
  <slide-over-right
    :id="sorId"
    editType="bulk-update"
    v-on:showModal="showModal"
  >
    <div class="h-50 ">
      <div
        class="flex flex-wrap justify-between px-8 pt-12 w-full border-b pb-2"
      >
        <div class="w-2/3  ">
          <p class="text-xl  font-semibold">
            Bulk Actions ({{ Object.keys(getHWALogs).length }})
          </p>

          <p class="text-sm text-oLightGray">
            {{ Object.keys(getHWALogs).length }} vehicle<span
              v-if="Object.keys(getHWALogs).length > 1"
              >s</span
            >
            will be {{ getTitleText }}
          </p>
        </div>
        <div v-if="isLoading">{{ countdownSecond }}s</div>
        <slide-over-right-trigger :id="sorId" v-else>
          <anchor-button variant="primary" @click="onDismiss"
            >Dismiss</anchor-button
          >
        </slide-over-right-trigger>
      </div>
      <!-- summary -->

      <div class="grid grid-cols-6 gap-y-3 gap-x-4 py-2  px-6 mt-5">
        <div
          class="col-span-2 px-4 py-2  font-semibold text-sm bg-gray-100 rounded-md h-16"
        >
          <div class="text-2xl">{{ getbulkItemHWASummary.processing }}</div>
          <small class="text-oLightGray uppercase text-xs">Processing</small>
        </div>
        <div
          class="col-span-2 px-4  py-2 font-semibold text-sm bg-gray-100 rounded-md h-16"
        >
          <div class="text-2xl text-green-500">
            {{ getbulkItemHWASummary.succeeded }}
          </div>
          <small class="text-oLightGray uppercase text-xs">Succeeded</small>
        </div>
        <div
          class="col-span-2 px-4 py-2  font-semibold text-sm bg-gray-100 rounded-md h-16"
        >
          <div class="text-2xl text-red-500">
            {{ getbulkItemHWASummary.failed }}
          </div>
          <small class="text-oLightGray uppercase text-xs">Failed</small>
        </div>
      </div>
      <!-- /summary -->
      <div class="grid grid-cols-6 gap-y-3  py-2  px-7 mt-5 ">
        <div class="col-span-2   flex justify-start font-semibold text-sm ">
          QR Code
        </div>
        <div class="col-span-2  flex justify-start font-semibold text-sm  ">
          Status
        </div>
        <!-- <div
          class="col-span-2 px-4   flex justify-start font-semibold text-sm "
        >
          Success
        </div> -->
      </div>
    </div>
    <div class="h-96 overflow-y-scroll ">
      <template v-for="(processKey, processIndex) in Object.keys(getHWALogs)">
        <div
          :key="processIndex"
          class="grid grid-cols-6 gap-y-3  py-2  px-7 text-xs "
        >
          <div class="col-span-2  flex justify-start  ">
            #{{ getHWALogs[processKey].vehicle.qr_code }}
          </div>

          <div class="col-span-2   flex justify-start   ">
            <template v-if="getHWALogs[processKey].type === 'processing'">
              <span class="text-gray-800">Processing</span>
            </template>

            <template v-else-if="getHWALogs[processKey].type === 'succeeded'">
              <span class="text-green-600">Succeeded</span>
              <i class="ml-2 text-green-500 fas fa-check-circle" />
            </template>

            <template v-else-if="getHWALogs[processKey].type === 'failed'">
              <span class="text-red-600">Failed</span>
              <i class="ml-2 text-oRed fas fa-exclamation-circle" />
            </template>
          </div>

          <!-- <div class="col-span-2 px-4  flex justify-start   ">
            <template v-if="getHWALogs[processKey].type === 'processing'">
              <i class="text-green-600 fas fa-spinner fa-pulse" />
            </template>

            <template v-else-if="getHWALogs[processKey].type === 'succeeded'">
              <i class="text-green-500 fas fa-check-circle" />
            </template>

            <template v-else-if="getHWALogs[processKey].type === 'failed'">
              <i class="text-oRed fas fa-exclamation-circle" />
            </template>
          </div> -->
        </div>
      </template>
    </div>
  </slide-over-right>
</template>

<script>
import { BulkProgressConfig } from '@/config/BulkProgressConfig'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import { SocketConfig } from '@/config/SocketConfig'
import ReconnectingWebsocket from 'reconnectingwebsocket'
export default {
  name: 'BulkProgress',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
  },
  props: {
    isListening: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: Object,
    },
  },

  data() {
    return {
      sorId: BulkProgressConfig.events.sorId,
      countdownSecond: 30,
      cIntervalId: null,
      isLoading: true,
      sockListener: null,
    }
  },
  watch: {
    isListening: {
      deep: true,
      immediate: true,
      handler(status) {
        if (status) {
          this.startListener()
        }
      },
    },
    countdownSecond: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 0) {
          clearInterval(this.cIntervalId)
          this.removeListener()
          this.isLoading = false
          Object.keys(this.getHWALogs).forEach((id) => {
            if (this.getHWALogs[id].type === 'processing') {
              this.$store.commit('fsTable/syncHWALogs', {
                key: id,
                type: 'failed',
              })
            }
          })
          this.$store.dispatch('fsTable/syncBulkSummary')
        }
        if (
          val > 0 &&
          this.getbulkItemHWASummary.total > 0 &&
          this.getbulkItemHWASummary.total ===
            this.getbulkItemHWASummary.succeeded
        ) {
          this.isLoading = false
        }
      },
    },
    isLoading: {
      deep: true,
      immediate: true,
      handler(status) {
        if (!status) {
          clearInterval(this.cIntervalId)
          this.removeListener()
        }
      },
    },
  },
  computed: {
    getHWALogs() {
      return this.$store.getters['fsTable/getbulkItemHWALogs']
    },
    getbulkItemHWASummary() {
      return this.$store.getters['fsTable/getbulkItemHWASummary']
    },
    getTitleText() {
      if (this.actionType.key === 'is_locked') {
        if (this.actionType.value) {
          return 'locked'
        } else {
          return 'unlocked'
        }
      } else {
        return ''
      }
    },
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(BulkProgressConfig.events.sorToggle))
    },
    onDismiss() {
      this.removeListener()
      clearInterval(this.cIntervalId)
      this.$store.dispatch('fsTable/fetchData')
      this.$store.dispatch('fsTable/syncBulkSummary', true)
      this.$store.commit('fsTable/setbulkItemHWALogs', {
        items: [],
      })
    },
    startListener() {
      this.cIntervalId = setInterval(() => {
        this.countdownSecond--
        if (this.countdownSecond % 2 === 0) {
          this.$store.dispatch('fsTable/syncBulkSummary')
        }
      }, 1000)

      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_updates') return
      // eslint-disable-next-line no-prototype-builtins
      const keyExist = this.getHWALogs.hasOwnProperty(payload.data.id)
      if (keyExist) {
        if (payload.data[this.actionType.key] === this.actionType.value) {
          this.$store.commit('fsTable/syncHWALogs', {
            key: payload.data.id,
            type: 'succeeded',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
