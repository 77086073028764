export const BulkProgressConfig = {
  api: {},
  events: {
    name: 'bulk-progress',
    // refresh-index-data
    refresh: `rid-bulk-progress`,
    // slideover-right
    sorId: 'bulk-progress',
    sorOpen: 'sor-open-bulk-progress',
    sorClose: 'sor-close-bulk-progress',
    sorToggle: 'sor-toggle-bulk-progress',
  },
}
