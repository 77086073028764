<template>
  <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="flex gap-5 items-center mb-5">
        <label class="cursor-pointer">
          <TRadioUnwrapped
            v-model="chooseLock"
            :value="false"
            :name="`chooseLock`"
          />

          <span class="ml-3 font-medium text-14px text-oDark">
            {{
              isEditing
                ? $t(
                    'components.vehicleManagement.addEdit.steps.iot.title.updateCurrentIoT'
                  )
                : $t(
                    'components.vehicleManagement.addEdit.steps.iot.title.addNewIoT'
                  )
            }}
          </span>
        </label>

        <label class="cursor-pointer">
          <TRadioUnwrapped
            v-model="chooseLock"
            :value="true"
            :name="`chooseLock`"
          />

          <span class="ml-3 font-medium text-14px text-oDark">
            {{
              isEditing
                ? $t(
                    'components.vehicleManagement.addEdit.steps.iot.title.replaceCurrentIoT'
                  )
                : $t(
                    'components.vehicleManagement.addEdit.steps.iot.title.useExistingIoT'
                  )
            }}
          </span>
        </label>
      </div>

      <template v-if="chooseLock">
        <AppInput
          v-model="selectedLock"
          type="richselect"
          :name="
            $t('components.vehicleManagement.addEdit.steps.iot.title.chooseIoT')
          "
          :label="
            $t('components.vehicleManagement.addEdit.steps.iot.title.chooseIoT')
          "
          :placeholder="
            $t(
              'components.vehicleManagement.addEdit.steps.iot.placeHolder.chooseIoT'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="text"
          :options="unassignedLocks"
        />

        <template v-if="selectedLockDetails">
          <!-- {{ selectedLockDetails }} -->

          <!-- row-1 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="selectedLockDetails.name"
              type="text"
              :name="
                $t('components.vehicleManagement.addEdit.steps.iot.title.name')
              "
              :label="
                $t('components.vehicleManagement.addEdit.steps.iot.title.name')
              "
              placeholder="--"
              rules="required"
              disabled
            />

            <AppInput
              v-model="selectedLockDetails.iot_category"
              type="text"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.ioTType'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.ioTType'
                )
              "
              rules="required"
              placeholder="--"
              disabled
            />
          </div>
          <!-- /row-1 -->

          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="selectedLockDetails.lock_id"
              type="text"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
                )
              "
              placeholder="--"
              disabled
            />

            <AppInput
              v-model="selectedLockDetails.id"
              type="text"
              name="ID"
              label="ID"
              placeholder="--"
              disabled
            />

            <!-- <AppInput
              v-model="selectedLockDetails.qr_code"
              type="text"
              name="QR Code"
              label="QR Code"
              placeholder="--"
            /> -->
          </div>
          <!-- /row-2 -->
        </template>
      </template>

      <template v-else>
        <AppInput
          v-model="form.iot_category"
          type="richselect"
          :name="
            $t('components.vehicleManagement.addEdit.steps.iot.title.ioTType')
          "
          :label="
            $t('components.vehicleManagement.addEdit.steps.iot.title.ioTType')
          "
          :placeholder="
            $t(
              'components.vehicleManagement.addEdit.steps.iot.placeHolder.ioTType'
            )
          "
          rules="required"
          value-attribute="value"
          text-attribute="display_name"
          :options="lockTypes"
          hide-search-box
        />

        <!-- row-1 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.name"
            type="text"
            :name="
              $t('components.vehicleManagement.addEdit.steps.iot.title.name')
            "
            :label="
              $t('components.vehicleManagement.addEdit.steps.iot.title.name')
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.placeHolder.name'
              )
            "
            rules="required"
          />

          <AppInput
            v-model="form.manufacturer"
            type="richselect"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.manufacturer'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.manufacturer'
              )
            "
            :placeholder="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.placeHolder.manufacturer'
              )
            "
            value-attribute="id"
            text-attribute="name"
            rules="required"
            :options="lockManufacturers"
            hide-search-box
          />
        </div>
        <!-- /row-1 -->

        <!-- row-2 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.lock_id"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.deviceIMEI'
              )
            "
            rules="required"
            placeholder="e.g. 987654321"
          />

          <AppInput
            v-model="form.factory_qr"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.factoryQRCode'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.factoryQRCode'
              )
            "
            placeholder="e.g. 10010001"
          />
        </div>
        <!-- /row-2 -->

        <!-- row-4 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.iccid"
            type="text"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.simICCID'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.simICCID'
              )
            "
            placeholder="e.g 898901757263760"
          />

          <AppInput
            v-model="form.sim_number"
            type="tel"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.simPhoneNumber'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.simPhoneNumber'
              )
            "
            placeholder="e.g. 01757263760"
          />
        </div>
        <!-- /row-4 -->

        <template v-if="isEditing">
          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.firmware_key"
              type="text"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.firmwareKey'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.firmwareKey'
                )
              "
              placeholder="e.g. yOTmK50"
            />

            <AppInput
              v-model="form.firmware_type"
              type="text"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.firmwareType'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.firmwareType'
                )
              "
              placeholder="e.g. ext2"
            />
          </div>
          <!-- /row-3 -->

          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.operator_apn"
              type="text"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.operatorAPN'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.operatorAPN'
                )
              "
              placeholder="e.g. yOTmK50"
            />

            <AppInput
              v-model="form.start_date"
              type="date"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.registrationDate'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.registrationDate'
                )
              "
              placeholder="dd/mm/yyyy"
              disabled
            />
          </div>
          <!-- /row-2 -->
        </template>

        <!-- row-5 -->
        <div>
          <TCheckbox
            v-model="useDescriptionField"
            :name="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.addDescriptionOptional'
              )
            "
            :label="
              $t(
                'components.vehicleManagement.addEdit.steps.iot.title.addDescriptionOptional'
              )
            "
            wrapped
            @change="onUseDescriptionField"
          />

          <transition name="fade">
            <AppInput
              ref="descriptionFieldRef"
              v-if="useDescriptionField"
              v-model="form.description"
              type="textarea"
              :name="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.description'
                )
              "
              :label="
                $t(
                  'components.vehicleManagement.addEdit.steps.iot.title.description'
                )
              "
              rules=""
              placeholder=""
            />
          </transition>
        </div>
        <!-- /row-5 -->
      </template>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'

import { getVehicleFormModel } from './index'

export default {
  name: 'VehicleAddEditStep1',

  components: {},

  props: {
    // vehicle.lock.id (created or selectedLockId)
    primaryKeyLock: {
      required: false,
    },
    // todo: remove this
    selectedLockId: {
      type: String,
      required: false,
    },
    lockTypes: {
      type: Array,
      required: true,
    },
    unassignedLocks: {
      type: Array,
      required: true,
    },
    lockManufacturers: {
      type: Array,
      required: true,
    },

    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: getVehicleFormModel({ step: 1 }),

      // the selected lock id (from choose/replace iot section)
      selectedLock: '',

      chooseLock: false,
      useDescriptionField: false,
    }
  },

  computed: {
    selectedLockDetails() {
      if (!this.selectedLock) return null

      return this.unassignedLocks.find(
        (uLock) => uLock.id === this.selectedLock
      )
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      if (this.chooseLock && this.selectedLock) {
        this.$notify(
          {
            group: 'bottomLeft',
            type: 'danger',
            title: 'Updated Selection',
            text: 'Finish the last step to persist changes.',
          },
          7000
        )
        this.$emit('save', {
          step: 1,
          data: this.selectedLockDetails,
          from: 'chooseLock',
        })

        return
      }
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if primaryKey is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit

      // NOTE: in case of locks, selectedLockId is the primary key

      const url =
        this.isEditing || this.primaryKeyLock
          ? useEndpoints.lock.update(this.primaryKeyLock)
          : useEndpoints.lock.create()

      const method = this.isEditing || this.primaryKeyLock ? 'PATCH' : 'POST'

      const formDataProxy = { ...this.form }

      // qr_code is essentially the same as lock_id (IMEI)
      formDataProxy.qr_code = this.form.lock_id

      // registration date is not modifiable
      delete formDataProxy.start_date

      const propertyNames = Object.keys(formDataProxy)
      propertyNames.map((name) => {
        if (formDataProxy[name] === null) {
          delete formDataProxy[name]
        }
      })

      const data = new xMan(formDataProxy).toFormData()

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          // todo: push to unassignedLocks
          console.log({ save: res.data })
          this.$emit('save', { step: 1, data: res.data })

          // todo: this.chooseLock && (this.isEditing || this.primaryKeyLock) ? 'is selected' : 'updated successfully'
          const message = `Lock ${
            this.isEditing || this.primaryKeyLock
              ? 'is selected'
              : 'added successfully'
          }`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err.response)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail ?? err.response.data,
          })
        })
        .finally(() => (this.isLoading = false))
    },

    onUseDescriptionField(e) {
      if (e) {
        const i = setTimeout(() => {
          this.$refs.descriptionFieldRef.focus()
          clearTimeout(i)
        }, 150)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
