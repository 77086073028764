<template>
  <section>
    <VehicleAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :primary-key-lock="addEdit.primaryKeyLock"
      :raw-data="addEdit.rawData"
      :vehicleType="vehicleType"
      :busy="addEdit.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />

    <google-map-modal-alt
      :marker-is-updatable="false"
      :variant="`vehicle`"
      :title="`Vehicle On The Map`"
      :subtitle="getMapVehicleSubtitle"
    />

    <content-section :spacing="false">
      <div
        class="flex flex-wrap items-center justify-between px-6 mb-4 content-spacing"
      >
        <div v-if="isAddAnother">
          <title-plus :title="pageTitle" @plus="addAnother" class="mb-5" />
        </div>
        <div v-else>
          <div v-if="isShowOnlyPageTitle">
            <oto-page-title :title="pageTitle" />
          </div>
          <div v-else class="flex items-center">
            <oto-page-title :title="pageTitle" />

            <div class="block" v-if="$acl.canCreate('vehicles')">
              <div @click="toggleDropdown" class="tooggle-btn">
                <img
                  src="@/assets/img/multiple-btn.png"
                  width="55"
                  height="30"
                />
              </div>

              <div
                v-bind:class="{
                  hidden: !dropdownPopoverShow,
                  block: dropdownPopoverShow,
                }"
                class="absolute float-left py-2 mt-1 text-base text-left list-none bg-white rounded shadow-2xl z-1"
                style="min-width:12rem"
                ref="popoverDropdownRef"
              >
                <div class="cursor-pointer item-btn" @click="add('single')">
                  {{
                    $t(
                      'components.vehicleManagement.addAction.addSingleVehicle'
                    )
                  }}
                </div>
                <div class="cursor-pointer item-btn" @click="add('multiple')">
                  {{
                    $t(
                      'components.vehicleManagement.addAction.addMultipleVehicles'
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row px-5"
      >
        <SummaryCard
          :title="$t('components.vehicleManagement.summary.totalVehicles')"
          :value="indexMetaData.summary.total"
          variant="gray"
        />
        <SummaryCard
          :title="$t('components.vehicleManagement.summary.onRide')"
          :value="indexMetaData.summary.on_ride"
          variant="gray"
        />
        <SummaryCard
          :title="$t('components.vehicleManagement.summary.idle')"
          :value="indexMetaData.summary.idle"
          variant="gray"
        />
      </div>
      <template v-if="$acl.canView('vehicles')">
        <FSTable
          :fst-id="fstId"
          :endpoint="endpoint"
          :qso="{
            append: '',
            prepend: '',
          }"
          :headers="getTableHeaders"
          exportFor="vehicle"
          :isQrCodeDownloadEnabled="true"
          :isHeaderChecked="true"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="getFilteredItems"
              />
            </keep-alive>
          </template>
          <template #topRight="{  }">
            <TakeActions v-if="getActionButtonStatus" />
          </template>

          <template v-slot:default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem>
                    <TCheckbox
                      :checked="item.isSelected"
                      :name="`selectFromList`"
                      :variant="`lg`"
                      @change="
                        $store.commit('fsTable/onSelect', {
                          key: itemIndex,
                          value: $event,
                        })
                      "
                      wrapped
                    />
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.name"
                    :to="{
                      name: 'ViewVehicleProfile',
                      params: { id: item.id, redirectPath: path },
                    }"
                  />

                  <FSTableRowItem>
                    <battery-status :level="item.lock.power_level" />
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.lock.lock_id" />

                  <FSTableRowItem :text="item.qr_code" />

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <span
                      v-text="
                        getUTCAwareTime(item.last_connected_at, {
                          relative: true,
                        })
                      "
                      :title="getUTCAwareTime(item.last_connected_at)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <span
                      v-text="
                        getUTCAwareTime(item.last_loc_updated_at, {
                          relative: true,
                        })
                      "
                      :title="getUTCAwareTime(item.last_loc_updated_at)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <template v-if="getEnabledFlags(item).length > 0">
                      <div class="flex items-center">
                        <template
                          v-for="(flag, flagIndex) in getEnabledFlags(item)"
                        >
                          <vehicle-flag-icon
                            v-tooltip.bottom="getFlagAlias(flag, item)"
                            size="sm"
                            class="mr-1"
                            :name="flag"
                            :key="`vehicle-${itemIndex}-flag-${flagIndex}`"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      No Flags
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <HeartbeatStatus
                      :status="item.heart_beat"
                      :is-available="item.is_available"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div
                      class="vehicle-map-icon"
                      @click="showGoogleMapModal(item)"
                    >
                      <i class="fas fa-map" />
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem v-if="fstId === 'marketplace-vehicles'">
                    <VehicleAssignToBuyerAction
                      :id="itemIndex"
                      :data="item"
                      @force-sync="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <div class="flex items-center">
                      <MoreActions
                        :data="item"
                        :currentItemIndex="itemIndex"
                        :vehicle-type="`scooter`"
                        takeActionFrom="vehicle-list"
                        :current-status="item.lock.is_locked"
                        :hibernateStatus="item.general_flags.hibernate_mode"
                        :redirectPath="path"
                        @edit="onOpenES({ primaryKey: $event.vehicleId })"
                        @change="item.lock.is_locked = $event"
                        @force-sync="$store.dispatch('fsTable/fetchData')"
                        @delete:failed="onDeleteFailed"
                        @delete:succeeded="onDeleteSucceeded"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <TCheckbox
                      :checked="item.isSelected"
                      :name="`selectAllFromList`"
                      :variant="`lg`"
                      @change="
                        $store.commit('fsTable/onSelect', {
                          key: itemIndex,
                          value: $event,
                        })
                      "
                      wrapped
                    />
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.name"
                    :to="{
                      name: 'ViewVehicleProfile',
                      params: { id: item.id, redirectPath: path },
                    }"
                  />

                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <HeartbeatStatus
                      :status="item.heart_beat"
                      :is-available="item.is_available"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem v-if="fstId === 'marketplace-vehicles'">
                    <VehicleAssignToBuyerAction
                      :id="itemIndex"
                      :data="item"
                      @force-sync="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem
                    v-if="fstId === 'marketplace-vehicles' ? false : true"
                  >
                    <div class="flex items-center">
                      <MoreActions
                        :data="item"
                        :currentItemIndex="itemIndex"
                        :vehicle-type="`scooter`"
                        takeActionFrom="vehicle-list"
                        :current-status="item.lock.is_locked"
                        :hibernateStatus="item.general_flags.hibernate_mode"
                        :redirectPath="path"
                        @edit="onOpenES({ primaryKey: $event.vehicleId })"
                        @change="item.lock.is_locked = $event"
                        @force-sync="$store.dispatch('fsTable/fetchData')"
                        @delete:failed="onDeleteFailed"
                        @delete:succeeded="onDeleteSucceeded"
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.battery'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <battery-status :level="item.lock.power_level" />
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.vehicleManagement.table.columns.imei')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.lock.lock_id || `--` }}
                      </div>
                    </div>

                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.qrCode'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          {{ item.qr_code || `--` }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles' ? false : true"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.lastConnected'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <span
                            v-text="
                              getUTCAwareTime(item.last_connected_at, {
                                relative: true,
                              })
                            "
                            :title="getUTCAwareTime(item.last_connected_at)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles' ? true : false"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.heartBeat'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <HeartbeatStatus
                            :status="item.heart_beat"
                            :is-available="item.is_available"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fstId === 'marketplace-vehicles' ? false : true"
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.lastLocated'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <span
                            v-text="
                              getUTCAwareTime(item.last_loc_updated_at, {
                                relative: true,
                              })
                            "
                            :title="getUTCAwareTime(item.last_loc_updated_at)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.vehicleManagement.table.columns.flags')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <template v-if="getEnabledFlags(item).length > 0">
                            <div class="flex items-center">
                              <template
                                v-for="(flag, flagIndex) in getEnabledFlags(
                                  item
                                )"
                              >
                                <vehicle-flag-icon
                                  v-tooltip.bottom="getFlagAlias(flag, item)"
                                  size="sm"
                                  class="mr-1"
                                  :name="flag"
                                  :key="
                                    `vehicle-${itemIndex}-flag-${flagIndex}`
                                  "
                                />
                              </template>
                            </div>
                          </template>
                          <template v-else>
                            No Flags
                          </template>
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.vehicleManagement.table.columns.lastLocation'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center">
                          <div
                            class="vehicle-map-icon"
                            @click="showGoogleMapModal(item)"
                          >
                            <i class="fas fa-map" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        {{ $t('components.acl.doNotHavePermission') }}
      </div>
    </content-section>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import { EventBus, getUTCAwareTime } from '@/utils'
import TitlePlus from '@/components/ui/TitlePlus'
import VehicleAddEdit from '@/composites/vehicle/add-edit/VehicleAddEdit.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
} from '@/components/fs-table'
import TakeActions from '@/composites/vehicle/shared/TakeActions.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'VehicleIndex',

  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    fstId: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    qso: {
      type: Object,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    isAddAnother: {
      type: Boolean,
      default: false,
    },
    isShowOnlyPageTitle: {
      type: Boolean,
      default: false,
    },
    vehicleType: {
      type: String,
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    OtoPageTitle: () => import('@/components/ui/OtoPageTitle'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    BatteryStatus: () => import('@/components/badge/BatteryStatus'),
    HeartbeatStatus: () => import('@/components/badge/HeartbeatStatus'),
    VehicleFlagIcon: () => import('@/components/badge/VehicleFlagIcon'),
    GoogleMapModalAlt: () => import('@/components/modals/GoogleMapModalAlt'),
    MoreActions: () => import('../shared/MoreActions.vue'),
    VehicleAssignToBuyerAction: () =>
      import('@/views/marketplace/modals/VehicleAssignToBuyerAction.vue'),
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    TakeActions,
    VehicleAddEdit,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      fallbackText: '--',
      path: '',
      indexMetaData: {
        summary: {
          total: 0,
          on_ride: 0,
          idle: 0,
        },
        count: {
          total: 0,
        },
      },
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.vehicleManagement.table.columns.name'),
          width: '35%',
          sort: null,
        },
        { text: 'H.Beat', width: '35%', sort: null },
        {
          text: this.$t('components.vehicleManagement.table.columns.actions'),
          width: '20%',
          sort: null,
        },
      ],
      marketplaceResTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.vehicleManagement.table.columns.name'),
          width: '35%',
          sort: null,
        },
        // { text: 'Heart Beat', width: '20%', sort: null },
        {
          text: this.$t('components.vehicleManagement.table.columns.assignTo'),
          width: '55%',
          sort: null,
        },
      ],
      opened: [],
      dropdownPopoverShow: false,

      mapVehicleData: {},
      mapModalState: false,
      mapModalLoc: {
        // lat: "90.2119",
        // lng: "27.21"
      },

      // generated by generateFilterOptions()
      filterOptions: [],

      // todo: export from another file & import it
      addEdit: {
        // EdgeStack component
        esId: 'vehicle-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  mounted() {
    EventBus.$on('force-refresh', () => {
      this.$store.dispatch('fsTable/fetchData')
    })
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        if (this.fstId === 'marketplace-vehicles') {
          return this.marketplaceResTableHeaders
        } else {
          return this.resTableHeaders
        }
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getMapVehicleSubtitle() {
      if (this.mapVehicleData && this.mapVehicleData.last_connected_at) {
        return (
          'Last Location Updated On: ' +
          dayjs(this.mapVehicleData.last_connected_at.split('.')[0]).format(
            'DD MMM, YYYY hh:mm:ss a'
          )
        )
      }
      return 'Last Location Updated On: --'
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        return this.filterItems
      } else {
        return this.filterOptions
      }
    },
    getActionButtonStatus() {
      if (this.$store.getters['fsTable/getData']) {
        const isSelected = this.$store.getters['fsTable/getData'].some(
          (item) => item.isSelected === true
        )
        return isSelected
      } else {
        return false
      }
    },
  },

  async created() {
    await this.generateFilterOptions()
    this.path = this.$route.path
  },

  methods: {
    getUTCAwareTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },

    // todo: export from another file & import it
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const vReq = this.$http.get(useEndpoints.vehicle.details(primaryKey))

        const requests = [vReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [vRes] = responses
              console.log(vRes.data, 'vRes')

              data = { ...vRes.data }

              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
              this.addEdit.rawData = data
              this.addEdit.primaryKey = primaryKey // for vehicle
              this.addEdit.primaryKeyLock = data.lock.id // for lock
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for vehicle
      this.addEdit.primaryKey = null // for vehicle
      this.addEdit.primaryKeyLock = null // for lock

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },

    onDeleteSucceeded() {
      this.$store.dispatch('fsTable/fetchData')
      this.$notify({
        group: 'bottomRight',
        type: 'success',
        title: 'Vehicle Deleted',
        text: `Vehicle has been deleted successfully.`,
      })
    },

    onDeleteFailed({ data }) {
      console.error('onDeleteFailed', data)
      this.$notify({
        group: 'bottomRight',
        type: 'error',
        title: 'Failed to Delete!',
        text: `${data.detail}`,
      })
    },

    toggleDropdown() {
      this.dropdownPopoverShow = !this.dropdownPopoverShow
    },

    getVehicleType(vehicle_type) {
      const types = {
        P: 'Scooter',
        S: 'Bike',
        E: 'E-Bike',
      }
      return types[vehicle_type] || '--'
    },
    addAnother() {
      this.$emit('add-another')
    },

    add(type) {
      this.dropdownPopoverShow = false

      // todo: move to parent component?
      if (type === 'single') {
        this.onOpenES({ primaryKey: null })
        return
      }

      this.$emit(`add:${type}`)
    },

    edit(vehicle) {
      this.$emit('edit', { vehicle })
    },

    showFlags(item) {
      let enabled = this.getEnabledFlags(item)

      let aliases = {
        charging_pick: 'Charging Pick',
        maintainance: 'On Maintainance',
        rebalance: 'Rebalance',
        hibernate_mode: 'Sleep Mode',
        restricted_alert: 'Restricted Alert',
        slowzone_alert: 'Slowzone Alert',
        geo_fence_alert: 'Out OF Geofence',
        iot_fault: 'IOT Fault',
        low_battery: 'low Battery',
        missing: 'Missing',

        is_charging: 'On Charging',
        is_parking: 'On Parking',
        is_reserved: 'On Reservation',
        is_on_ride: 'On Ride',
        is_idle: 'Idle',
        inspection_required: 'Inspection Required',
        re_inspection: 'Reinspection Required',
        under_inspection: 'Under Inspection',
        repair_required: 'Repair Required',
        repair_backlog: 'Repair Backlog',
        battery_swap_required: 'Battery Swap Required',
        battery_swap_in_progress: 'Battery Swap In Progress',
        docked_charging_adapter: 'Docked',
        none: 'None',
      }

      let res = []
      for (const key in aliases) {
        if (enabled.includes(key)) {
          res.push(aliases[key])
        }
      }
      // console.log({ resss: res });
      // console.log(res.join(", "));
      return res.join(', ')
    },
    getEnabledFlags(item) {
      let flags = {
        ...item.general_flags,
        ...item.operational_flags,
        ...item.status_flags,
      }

      // console.log(flags);
      let enabled = []

      for (const key in flags) {
        console.log('v-flag', key, flags[key])
        if (key !== 'is_idle' && flags[key] === true) {
          enabled.push(key)
        }
      }
      if (
        item.inspection_settings &&
        item.inspection_settings.inspection_status === 'REQUIRED'
      ) {
        enabled.push('inspection_required')
      }
      if (
        item.inspection_settings &&
        item.inspection_settings.inspection_status === 'RE_INSPECTION'
      ) {
        enabled.push('re_inspection')
      }
      if (
        item.inspection_settings &&
        item.inspection_settings.inspection_status === 'UNDER_INSPECTION'
      ) {
        enabled.push('under_inspection')
      }
      if (
        item.inspection_settings &&
        item.inspection_settings.inspection_status === 'REPAIR_REQUIRED'
      ) {
        enabled.push('repair_required')
      }
      if (
        item.inspection_settings &&
        item.inspection_settings.inspection_status === 'REPAIR_BACKLOG'
      ) {
        enabled.push('repair_backlog')
      }
      if (
        item.battery_settings &&
        item.battery_settings.battery_swap_status === 'REQUIRED'
      ) {
        enabled.push('battery_swap_required')
      }
      if (
        item.battery_settings &&
        item.battery_settings.battery_swap_status === 'SWAP_IN_PROGRESS'
      ) {
        enabled.push('battery_swap_in_progress')
      }

      if (
        item.charging_adapter &&
        item.charging_adapter.docking_status === 'DOCKED'
      ) {
        enabled.push('docked_charging_adapter')
      }

      console.log('enabled', enabled)
      return enabled
    },
    getFlagAlias(flag, data = {}) {
      let aliases = {
        charging_pick: 'Charging Pick',
        maintainance: 'On Maintenance',
        rebalance: 'Rebalance',
        swap_battery: 'Swap Battery',
        hibernate_mode: 'Sleep Mode',

        restricted_alert: 'Restricted Alert',
        slowzone_alert: 'Slowzone Alert',
        geo_fence_alert: 'Geofence Alert',
        iot_fault: data.general_flags.fault_status_reason,
        low_battery: 'low Battery',
        missing: 'Missing',

        is_charging: 'On Charging',
        is_parking: 'On Parking',
        is_reserved: 'On Reservation',
        is_on_ride: 'On Ride',
        is_idle: 'Idle',
        inspection_required: 'Inspection Required',
        re_inspection: 'Reinspection Required',
        under_inspection: 'Under Inspection ',
        repair_required: 'Repair Required',
        repair_backlog: 'Repair Backlog',
        battery_swap_required: 'Battery Swap Required',
        battery_swap_in_progress: 'Battery Swap In Progress',
        docked_charging_adapter: 'Docked',
        none: 'None',
      }
      return flag in aliases ? aliases[flag] : '--'
    },

    showGoogleMapModal(e) {
      // set current popup vehicle data to generate popup subtitle
      this.mapVehicleData = e

      EventBus.$emit('gmap-popup-alt', e)
      this.$modal.show('gmap-popup-alt')
    },

    async generateFilterOptions() {
      let filters = [
        {
          key: 'status_flag',
          type: 'checkbox',
          input: [
            { text: 'On Rent', value: 'on_ride' },
            { text: 'On Reservation', value: 'on_reservation' },
            { text: 'On Parking', value: 'on_parking' },
            { text: 'On Illegaly Parked', value: 'illegally_parking' },
            { text: 'On Charging', value: 'on_charging' },
            { text: 'On Idle', value: 'idle' },
            { text: 'Damaged', value: 'damaged' },
          ],
          title: 'Status Flags',
        },
        {
          key: 'unlocked_by',
          type: 'checkbox',
          input: [
            { text: 'Admin', value: 'A' },
            { text: 'Rider', value: 'R' },
            { text: 'Operator', value: 'O' },
            { text: 'Suspicious', value: 'U' },
          ],
          title: 'Last Unlocked By',
        },

        {
          key: 'general_flag',
          type: 'checkbox',
          input: [
            { text: 'Missing', value: 'missing' },
            { text: 'IoT Fault', value: 'iot_fault' },
            { text: 'GeoFence Alert', value: 'geofence_alert' },
          ],
          title: 'General Flags',
        },
        {
          key: 'inspection_status',
          type: 'checkbox',
          input: [
            { text: 'Not Required', value: 'NOT_REQUIRED' },
            { text: 'Required', value: 'REQUIRED' },
            { text: 'Under Inspection', value: 'UNDER_INSPECTION' },
            { text: 'Repair Required', value: 'REPAIR_REQUIRED' },
            { text: 'Repair Backlog', value: 'REPAIR_BACKLOG' },
            { text: 'Re Inspection', value: 'RE_INSPECTION' },
          ],
          title: 'Inspection Flag',
        },
        {
          key: 'battery_swap_status',
          type: 'checkbox',
          input: [
            { text: 'Required', value: 'REQUIRED' },
            { text: 'In Progress', value: 'SWAP_IN_PROGRESS' },
          ],
          title: 'Battery Swap Status',
        },
        {
          key: 'ops_flag',
          type: 'checkbox',
          input: [
            { text: 'Rebalance', value: 'rebalance' },
            { text: 'Charging Pick', value: 'charging_pick' },
            { text: 'Maintenance', value: 'maintenance' },
            { text: 'Swap Battery', value: 'swap_battery' },
          ],
          title: 'Operational Flags',
        },

        {
          key: 'active',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'true' },
            { text: 'Inactive', value: 'false' },
          ],
          title: 'Connectivity',
        },

        {
          key: 'locked',
          type: 'checkbox',
          input: [
            { text: 'Locked', value: 'true' },
            { text: 'Unlocked', value: 'false' },
          ],
          title: 'Lock',
        },
        {
          key: 'speed_mode',
          type: 'checkbox',
          input: [
            { text: 'Low', value: 'L' },
            { text: 'Medium', value: 'M' },
            { text: 'High', value: 'H' },
          ],
          title: 'Lock',
        },
        {
          key: 'docking_status',
          type: 'checkbox',
          input: [
            { text: 'Docked', value: 'DOCKED' },
            { text: 'Undocked', value: 'UNDOCKED' },
          ],
          title: 'Dock Status',
        },

        {
          key: 'fetch_test_vehicles',
          type: 'checkbox',
          input: [{ text: 'Yes', value: true }],
          title: 'Include Test Vehicle',
        },
        {
          key: 'power',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 100,
            minQs: 'min_power_level', // query string for min value
            maxQs: 'max_power_level', // query string for max value
          },
          title: 'Battery',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http
          .get('/dashboard/fleets/?export=true')
          .then(({ data }) => {
            if (data.data.length) {
              data.data.forEach((fleet) => {
                filterableFleets.push({ text: fleet.name, value: fleet.id })
              })
            }
          })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
      // console.warn({ flGen: filterableFleets }, this.filterOptions)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}

.tooggle-btn {
  cursor: pointer;
}
.tooggle-btn:hover {
  opacity: 0.8;
}
.tooggle-btn:active {
  opacity: 0.8;
}
.item-btn {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.item-btn:hover {
  background: #f3f3f3;
}
.vehicle-map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  width: 27px !important;
  height: 27px !important;
  border-radius: 5px;
  background-color: #ff598a;
  cursor: pointer;
  margin-right: 0.5rem;
}
.lock-unlock-dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 110px;
  height: 20px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }
}
</style>
