var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex w-auto mt-2 md:items-center md:justify-end md:mt-0"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"w-36 h-8 flex justify-center bg-gray-900 text-oWhite rounded-full px-2 py-1 relative",attrs:{"aria-label":"Take Actions","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.lockReq)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#fff","active":true,"loader":"bars","is-full-page":false}}):_vm._e(),(!_vm.lockReq)?_c('span',[_c('span',{staticClass:"font-medium text-14px"},[_vm._v("Take Actions")]),_c('i',{staticClass:"ml-2 fas fa-chevron-down text-14px"})]):_vm._e()],1)]}},{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-lg",on:{"click":function($event){return hide()}}},[(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":function($event){return _vm.handleHWAction('lock')}}},[_vm._v(" Lock ")]):_vm._e(),(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":function($event){return _vm.handleHWAction('unlock')}}},[_vm._v(" Unlock ")]):_vm._e(),(_vm.$acl.canUpdate('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.handleDeleteAction}},[_vm._v(" Delete ")]):_vm._e(),(_vm.$acl.canView('vehicles'))?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":_vm.exportQrCode}},[_vm._v(" Download QRCode ")]):_vm._e()],1)]}}])})],1),_c('BulkProgress',{attrs:{"isListening":_vm.isListeningStatus,"actionType":_vm.actionTypeObject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }