<template>
  <div class="card bg-gray-100 p-3">
    <div class=" grid grid-cols-12 ">
      <div class="col-span-2">
        <img :src="iData.photo_url" alt="image" class=" w-16 h-16 rounded-md" />
      </div>
      <div class="col-span-9">
        <p class="font-bold pl-2 text-lg">{{ iData.name }}</p>
      </div>
      <div class="col-span-1 cursor-pointer" @click="toggleFailReason">
        <i v-if="showFailReason" class=" fas fa-chevron-up"></i>
        <i v-else class=" fas fa-chevron-down"></i>
      </div>
    </div>
    <div class="mt-5 grid grid-cols-12 gap-2">
      <div class="col-span-6">
        <AppButton
          :variant="iData.isPassClicked ? 'green' : 'light-green'"
          width="100%"
          @click="handlePassFailClickStatus('pass')"
        >
          {{
            $t('components.vehicleInspectionAndRepairManagement.action.pass')
          }}
        </AppButton>
      </div>
      <div class="col-span-6">
        <AppButton
          :variant="
            iData.isFailedClicked
              ? isFailedReasonSelected
                ? 'red'
                : 'border-red-bg-gray'
              : 'gray-red'
          "
          width="100%"
          @click="handlePassFailClickStatus('fail')"
        >
          {{
            $t('components.vehicleInspectionAndRepairManagement.action.fail')
          }}
        </AppButton>
      </div>
    </div>
    <accordion-transition :expanded="showFailReason">
      <div
        class="mt-5"
        :key="`vi-${itemIndex}`"
        v-for="(reason, itemIndex) in iData.sub_parts"
      >
        <t-checkbox
          wrapped
          :label="reason.name"
          :name="reason.name"
          v-model="reason.isChecked"
          @change="handleReasonCheckedStatus(itemIndex, $event)"
        />
      </div>
    </accordion-transition>
  </div>
</template>
<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
export default {
  components: { AccordionTransition },
  name: 'InspectionItemCard',
  props: {
    iData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFailReason: false,
    }
  },
  computed: {
    isFailedReasonSelected() {
      if (this.iData.sub_parts.find((subpart) => subpart.isChecked)) return true
      else return false
    },
  },
  watch: {
    iData: {
      deep: true,
      immediate: false,
      handler(data) {
        if (data.isPassClicked) {
          this.showFailReason = false
        }
      },
    },
  },
  methods: {
    toggleFailReason() {
      this.showFailReason = !this.showFailReason
    },
    handlePassFailClickStatus(type) {
      if (type === 'pass') {
        this.$emit('pass-fail-click-status', type)
        this.showFailReason = false
      }
      if (type === 'fail') {
        this.$emit('pass-fail-click-status', type)
        this.showFailReason = true
      }
    },
    handleReasonCheckedStatus(childIndex, event) {
      this.$emit('change-reason', { childIndex, value: event })
      if (this.iData.sub_parts.find((subpart) => subpart.isChecked)) {
        this.iData.isFailedClicked = true
        this.iData.isPassClicked = false
      } else {
        this.iData.isFailedClicked = false
        this.iData.isPassClicked = false
      }
    },
  },
}
</script>
<style lang="scss">
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply rounded-lg;
}
</style>
