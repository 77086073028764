<template>
  <div class="card bg-gray-100 p-3">
    <div class=" grid grid-cols-12 ">
      <div class="col-span-2">
        <img :src="rData.photo_url" alt="image" class=" w-16 h-16 rounded-md" />
      </div>
      <div class="col-span-9 flex items-center">
        <div>
          <p class="font-bold pl-2 text-lg">{{ rData.name }}</p>
          <p class="pl-2 text-xs">
            {{
              $t(
                'components.vehicleInspectionAndRepairManagement.title.repairRemaining'
              )
            }}
            ({{ rData.sub_parts.length }})
          </p>
        </div>
      </div>
    </div>
    <div
      :key="`vr-${subpartItemIndex}`"
      v-for="(repair, subpartItemIndex) in rData.sub_parts"
      class=" bg-red-100 rounded-md mt-2"
    >
      <div class="p-3">
        <div class="grid grid-cols-12">
          <div class="col-span-1 flex items-center">
            <i class="fas fa-triangle-exclamation text-red-500 text-xl"></i>
          </div>
          <div class="col-span-9 flex items-center">
            <div class="flex items-center gap-1">
              <p class=" text-red-500">{{ repair.name }}</p>
            </div>
          </div>
          <div
            class="col-span-2 flex items-center justify-end cursor-pointer"
            @click="toggleRepairOptionView(subpartItemIndex)"
          >
            <div>
              <i class=" fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>

      <div v-if="repair.isShowOption" class="mt-2 bg-oWhite rounded-md py-2">
        <template
          v-for="(repairOption,
          repairOptionItemIndex) in repair.available_repair_options"
        >
          <div
            :key="`vrr-${repairOptionItemIndex}`"
            class="px-3 py-1 rounded-md "
          >
            <accordion-transition :expanded="repair.isShowOption">
              <div>
                <!-- <t-checkbox
                  wrapped
                  :label="repairOption.name"
                  :name="repairOption.name"
                  v-model="repairOption.isChecked"
                  @change="
                    handleReasonCheckedStatus(
                      subpartItemIndex,
                      repairOptionItemIndex,
                      $event
                    )
                  "
                /> -->
                <label class="flex items-center">
                  <t-radio
                    :name="`vr-${repair.name}-option-${subpartItemIndex}`"
                    @change="
                      handleReasonCheckedStatus(
                        subpartItemIndex,
                        repairOptionItemIndex,
                        true
                      )
                    "
                  />
                  <span class="ml-2 text-sm font-semibold">{{
                    repairOption.name
                  }}</span>
                </label>
              </div>
            </accordion-transition>
          </div>
        </template>
        <!-- <div class="px-3 py-5">
          <AppButton variant="light-green" width="100%" @click="closeModal">
            Confirm
          </AppButton>
        </div> -->
      </div>
    </div>
    <!-- <div class="mt-2 bg-gray-200">
      <div class="p-3  rounded-md">
        <div class="grid grid-cols-12">
          <div class="col-span-1 flex items-center">
            <i class="fas fa-ban text-gray-500 text-xl"></i>
          </div>
          <div class="col-span-9 flex items-center">
            <div>
              <p class=" pl-2 text-gray-500">Can't Repair Now</p>
            </div>
          </div>
          <div
            class="col-span-2 flex items-center justify-end cursor-pointer"
            @click="toggleCantRepairView"
          >
            <div>
              <i class=" fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="cantRepairViewStatus" class="mt-2 bg-oWhite rounded-md ">
        <div class="px-3 py-1 rounded-md ">
          <accordion-transition :expanded="cantRepairViewStatus">
            <div>
              <t-checkbox
                wrapped
                label="Take too much time"
                name="Take too much time"
              />
              <t-checkbox
                wrapped
                label="Not Found required parts"
                name="Not Found required parts"
              />
              <t-checkbox
                wrapped
                label="Safety Concern"
                name="Safety Concern"
              />
              <t-checkbox wrapped label="Others" name="Others" />
            </div>
          </accordion-transition>
        </div>

        <div class="px-3 py-5">
          <AppButton variant="light-green" width="100%" @click="closeModal">
            Confirm
          </AppButton>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
import { VehicleRepairConfig } from '@/config/VehicleRepairConfig'
export default {
  components: { AccordionTransition },
  name: 'RepairItemCard',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    rData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cantRepairViewStatus: false,
    }
  },

  methods: {
    toggleRepairOptionView(index) {
      this.rData.sub_parts[index].isShowOption = !this.rData.sub_parts[index]
        .isShowOption
    },
    toggleCantRepairView() {
      this.cantRepairViewStatus = !this.cantRepairViewStatus
    },
    closeModal() {
      dispatchEvent(new Event(VehicleRepairConfig.events.sorToggle(this.id)))
    },
    handleReasonCheckedStatus(subpartItemIndex, repairOptionItemIndex, event) {
      this.$emit('change-reason', {
        subpartItemIndex,
        repairOptionItemIndex,
        value: event,
      })
    },
  },
}
</script>
<style lang="scss">
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply rounded-lg;
}
</style>
