var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":(_vm.sorId + "-" + _vm.id),"editType":"swap-battery-workflow"},scopedSlots:_vm._u([(
      _vm.workflowData &&
        _vm.getLastCompletedItemData.completedIndex ===
          _vm.workflowData.items.length - 1
    )?{key:"footer",fn:function(){return [_c('div',{staticClass:"flex flex-wrap justify-end "},[_c('AppButton',{attrs:{"height":"40px","isLoading":_vm.isSubmitLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" COMPLETED ")])],1)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"flex items-center justify-start mt-5 gap-2"},[_c('div',{staticClass:"flex items-center justify-center cursor-pointer back-button",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-arrow-right"})]),_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.titleText))])]),_c('div',{staticClass:"flex  w-full p-3 mt-5  bg-gray-50 rounded-md "},[_c('div',{staticClass:"w-1/3 "},[_vm._v(_vm._s(_vm.vehicleProfile.name))]),_c('div',{staticClass:"w-1/3 flex justify-center"},[_c('BatteryStatus',{attrs:{"level":_vm.getBatteryLevel,"height":"18px","width":"35px","text-class":"text-xs text-gray-600 ml-2"}})],1),_c('div',{staticClass:"w-1/3 flex justify-end "},[_vm._v(" "+_vm._s(_vm.vehicleProfile.qr_code)+" ")])]),(_vm.workflowData)?_c('div',{staticClass:"mt-5"},_vm._l((_vm.workflowData.items),function(item,itemIndex){return _c('div',{key:("sb-" + itemIndex)},[_c('div',{staticClass:" list-container",class:"flex  w-full mt-0.5"},[_c('div',{staticClass:" left-side flex flex-col items-center justify-center"},[_c('div',{staticClass:"flex items-center justify-center"},[(item.status === 'IN_PROGRESS')?_c('i',{staticClass:"fa-solid fa-circle-half-stroke text-gray-700 text-xl"}):(item.status === 'COMPLETED')?_c('i',{staticClass:"fa-regular fa-circle-check text-green-500 text-xl"}):(item.status === 'FAILED')?_c('i',{staticClass:"fa-regular fa-circle-dot text-gray-700 text-xl"}):_c('i',{staticClass:"fa-regular fa-circle text-gray-300 text-xl"})]),_c('div',{staticClass:"w-0.5 h-full",class:((itemIndex !== _vm.workflowData.items.length - 1
                  ? item.status === 'COMPLETED'
                    ? 'bg-green-500'
                    : 'bg-gray-300'
                  : 'bg-oWhite') + " ")})]),_c('div',{staticClass:" right-side flex  justify-between "},[_c('div',[_c('p',{staticClass:"font-medium  text-md ",class:item.status === 'PENDING' ? 'text-gray-500' : 'text-oBlack'},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"text-xs",class:_vm.getActionStatusClass(item)},[_vm._v(" "+_vm._s(_vm.getActionStatusText(item))+" "+_vm._s(item.status === 'IN_PROGRESS' && item.mode === 'automatic' && _vm.timeCounterInSec !== null ? ("(" + _vm.timeCounterInSec + "s)") : '')+" ")])]),_c('div',{staticClass:"flex items-center justify-center"},[(item.status === 'FAILED')?[_c('AppButton',{attrs:{"height":"30px","isLoading":_vm.isManualActionLoading},on:{"click":function($event){return _vm.handleManualAction(item.code)}}},[_vm._v(" "+_vm._s(_vm.failedCounter === 3 ? 'Skip' : 'Retry')+" ")])]:_vm._e(),(item.status === 'IN_PROGRESS' && item.mode === 'manual')?[_c('AppButton',{attrs:{"height":"30px","isLoading":_vm.isManualActionLoading},on:{"click":function($event){return _vm.handleManualAction('confirm_prompt')}}},[_vm._v(" Continue ")])]:_vm._e()],2)])])])}),0):_vm._e(),_c('ConfirmFirst',{key:("confirm_prompt" + _vm.id),attrs:{"id":("confirm_prompt" + _vm.id),"isLoading":_vm.isLoading,"title":((_vm.getCurrentInProgressItemData.progressData
          ? _vm.getCurrentInProgressItemData.progressData.title
          : '') + " now"),"subtitle":"Please complete the task now.Slow internet might be effect to the task.","confirmButtonText":"Completed","cancelButtonVariant":"secondary","confirmButtonVariant":"primary"},on:{"confirm":function($event){return _vm.handleManualAction(
        _vm.getCurrentInProgressItemData.progressData
          ? _vm.getCurrentInProgressItemData.progressData.code
          : ''
      )},"cancel":function($event){return _vm.handleManualAction('confirm_prompt_cancel')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }