<template>
  <div>
    <div class="flex w-auto mt-2 md:items-center md:justify-end md:mt-0">
      <t-dropdown variant="smActions">
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            class="w-36 h-8 flex justify-center bg-gray-900 text-oWhite rounded-full px-2 py-1 relative"
            aria-label="Take Actions"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <loading
              :height="25"
              :opacity="0"
              color="#fff"
              v-if="lockReq"
              :active="true"
              loader="bars"
              :is-full-page="false"
            />
            <span v-if="!lockReq">
              <span class="font-medium text-14px">Take Actions</span>
              <i class="ml-2 fas fa-chevron-down text-14px"></i>
            </span>
          </button>
        </template>

        <template v-slot:default="{ hide }">
          <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-lg">
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="handleHWAction('lock')"
            >
              Lock
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="handleHWAction('unlock')"
            >
              Unlock
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="handleDeleteAction"
            >
              Delete
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canView('vehicles')"
              class="font-medium text-14px"
              @click="exportQrCode"
            >
              Download QRCode
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>
    <BulkProgress
      :isListening="isListeningStatus"
      :actionType="actionTypeObject"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { httpClient } from '@/services'
import jsPDF from 'jspdf'
import QRCode from 'qrcode'
import { BulkProgressConfig } from '@/config/BulkProgressConfig'
import BulkProgress from '@/composites/vehicle/shared/BulkProgress.vue'
export default {
  name: 'TakeActions',

  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    BulkProgress,
  },

  mounted() {},

  data() {
    return {
      isListeningStatus: false,
      lockReq: false,
      actionTypeObject: {},
    }
  },

  computed: {},

  methods: {
    async handleHWAction(actionType) {
      this.lockReq = true
      this.$store.commit('fsTable/setbulkItemHWALogs', {
        items: [],
      })
      const actionMap = {
        lock: {
          key: 'is_locked',
          value: true,
        },
        unlock: {
          key: 'is_locked',
          value: false,
        },
      }
      this.actionTypeObject = actionMap[actionType]
      const actionableVehicles = this.$store.getters['fsTable/getData'].filter(
        (v) => v.isSelected
      )

      if (actionableVehicles.length > 0) {
        const hwaLogs = actionableVehicles.map((item) => {
          return {
            id: item.id,
            type: 'processing',
            vehicle: {
              qr_code: item.qr_code,
            },
          }
        })
        this.$store.commit('fsTable/setbulkItemHWALogs', {
          items: hwaLogs,
        })

        const vIDs = actionableVehicles.map((v) => v.id)

        const method = 'post'
        const url = useEndpoints.vehicle.bulkUpdateStatus()
        const data = new FormData()

        for (let i = 0; i < vIDs.length; i++) {
          // console.log({ key })
          data.append('bikes', vIDs[i])
        }

        data.append(actionMap[actionType].key, actionMap[actionType].value)

        await httpClient({ url, method, data })
          .then(({ data }) => {
            this.isListeningStatus = true
            console.log({ bulkSuccess: data })
            dispatchEvent(new Event(BulkProgressConfig.events.sorToggle))
            // this.$store.dispatch('fsTable/fetchData')
          })
          .catch(({ response }) => {
            console.warn({ bulkErr: response })
            this.isListeningStatus = false
            this.$store.commit('fsTable/setbulkItemHWALogs', {
              items: [],
            })
          })
          .finally(() => (this.lockReq = false))
      }
    },
    async handleDeleteAction() {
      this.lockReq = true
      const actionableVehicles = this.$store.getters['fsTable/getData'].filter(
        (v) => v.isSelected
      )
      const vIDs = actionableVehicles.map((v) => v.id)

      const method = 'post'
      const url = useEndpoints.vehicle.bulkDelete()
      const data = new FormData()

      for (let i = 0; i < vIDs.length; i++) {
        data.append('bikes', vIDs[i])
      }

      await httpClient({ url, method, data })
        .then(() => {
          this.$store.dispatch('fsTable/fetchData')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `Delete Successfully`,
            },
            3000
          )
        })
        .catch(({ response }) => {
          console.warn({ bulkErr: response })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: response,
            },
            3000
          )
        })
        .finally(() => (this.lockReq = false))
    },
    exportQrCode() {
      const selectedVehicles = this.$store.getters['fsTable/getData'].filter(
        (v) => v.isSelected
      )
      if (selectedVehicles.length > 0) {
        const qrCodes = selectedVehicles.map((v) => v.qr_code)

        this.generatePDF(qrCodes)
      }
    },
    async generatePDF(qrCodes) {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
        compress: true,
        fontSize: 8,
        lineHeight: 1.2,
        autoPageBreak: true,
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
      })
      const x = 26
      const y = 26
      const width = 56
      const height = 56
      for (let i = 0; i < qrCodes.length; i++) {
        let xPos = 0
        let yPos = 0

        xPos = x + (i % 5) * (width + x)
        yPos = y + Math.floor((i % 35) / 5) * (height + y)
        const qrCode = await QRCode.toDataURL(this.getQrCodeURL(qrCodes[i]))
        if (i % 35 === 0 && i > 0) {
          pdf.addPage()
        }
        pdf.addImage(qrCode, 'JPEG', xPos, yPos, width, height)
        pdf.setFontStyle('bold')
        pdf.text(
          qrCodes[i],
          this.getXpositionForTextInPdf(xPos + 8, qrCodes[i]),
          yPos + height + 2
        )
      }
      pdf.save(`vehicle-qrcodes-${qrCodes.length}.pdf`)
    },
    getXpositionForTextInPdf(x, text) {
      if (text.length === 2) {
        return x + 16
      } else if (text.length === 3) {
        return x + 15
      } else if (text.length === 4) {
        return x + 13
      } else if (text.length === 5) {
        return x + 10
      } else if (text.length === 6) {
        return x + 9
      } else if (text.length === 7) {
        return x + 8
      } else if (text.length === 8) {
        return x + 7
      } else if (text.length === 9) {
        return x + 5
      } else if (text.length === 10) {
        return x + 4
      } else if (text.length === 11) {
        return x + 2
      } else {
        return x
      }
    },
    getQrCodeURL(qr_code) {
      const orgDomain = JSON.parse(localStorage.getItem('organization')).domain
      return `https://${orgDomain}/${qr_code || '--'}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
