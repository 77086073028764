<template>
  <section>
    <div
      :class="getWrapperClasses"
      @click="onClick"
      @dragover="onDragover"
      @dragleave="onDragleave"
      @drop="onDrop"
    >
      <input
        ref="file"
        type="file"
        class="overflow-hidden absolute w-0 h-0 opacity-0"
        accept=".kml"
        @change="onChange"
      />

      <div key="upload-preview-or-placeholder" class="grid place-items-center">
        <button
          v-tooltip.top="`Import KML File`"
          type="button"
          class="upload-button"
        >
          <i class="text-lg text-center fas fa-cloud-upload-alt"></i>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UploadGeoFile',

  data() {
    return {}
  },
  computed: {
    getWrapperClasses() {
      return 'upload-wrapper'
    },
  },
  methods: {
    onChange() {
      const input = this.$refs.file
      const file = input.files

      if (file && file[0]) {
        let data
        var kmlReader = new FileReader()
        kmlReader.onload = function(e) {
          data = e.target.result
        }
        kmlReader.readAsText(file[0])
        setTimeout(() => {
          this.$emit('input', data)
        }, 1000)
      }
    },
    onClick() {
      this.$refs.file.click()
    },
    onDragover(event) {
      console.log('dragover = ', event)
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('onDragover')) {
        event.currentTarget.classList.add('onDragover')
      }
    },
    onDragleave(event) {
      // Clean up
      console.log('dragleave = ', event)
      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.add('onDragleave')
    },
    onDrop(event) {
      console.log('drop = ', event)
      event.preventDefault()

      this.$refs.file.files = event.dataTransfer.files
      // Trigger the onChange event manually
      this.onChange()

      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.remove('onDragleave')
      event.currentTarget.classList.add('onDrop')
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-wrapper {
  background: #f3f3f3;
  width: 80px;
  height: 38px;
  overflow: hidden;
  @apply relative rounded border-dotted border-2 border-gray-300 items-center justify-center flex cursor-pointer;
  @apply hover:bg-gray-100 hover:border-blue-300;

  &.onDragover {
    @apply bg-green-50 border-green-200;
  }

  &.onDragleave,
  &.onDrop {
    background: #f3f3f3;
  }
}
</style>
