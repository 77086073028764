<template>
  <slide-over-right
    :id="`${sorId}-${id}`"
    editType="standard"
    ref="slideOverRightInspection"
  >
    <loading :active.sync="isLoading" :is-full-page="false"></loading>

    <div class="flex items-center justify-start mt-5 gap-2">
      <div
        class="flex items-center justify-center cursor-pointer back-button"
        @click="goBack"
      >
        <i class="fas fa-arrow-right"></i>
      </div>
      <div class="header-title">{{ titleText }}</div>
    </div>

    <div class="flex  w-full p-3 mt-5  bg-gray-50 rounded-md ">
      <div class="w-1/3 ">{{ vehicleProfile.name }}</div>
      <div class="w-1/3 flex justify-center">
        <BatteryStatus
          :level="getBatteryLevel"
          :height="`18px`"
          :width="`35px`"
          :text-class="`text-xs text-gray-600 ml-2`"
        />
      </div>
      <div class="w-1/3 flex justify-end ">
        {{ vehicleProfile.qr_code }}
      </div>
    </div>
    <div
      class="mt-5"
      :key="`vi-${itemIndex}`"
      v-for="(item, itemIndex) in inspectionData"
    >
      <InspectionItemCard
        :iData="item"
        @pass-fail-click-status="(e) => changePassFailClickStatus(e, itemIndex)"
        @change-reason="(data) => handleSubpart(itemIndex, data)"
      />
    </div>

    <div class=" mt-6">
      <div class="card bg-gray-100 flex flex-col w-full  gap-2 p-3">
        <AppButton
          :variant="getAllPassedClickedStatus ? 'green' : 'gray-green'"
          width="100%"
          @click="handlePassAll()"
        >
          {{
            $t('components.vehicleInspectionAndRepairManagement.action.passAll')
          }}
        </AppButton>
        <AppButton
          variant="primary"
          width="100%"
          @click="getFailedSubpartIds.length > 0 && handleSubmit()"
          :disabled="getFailedSubpartIds.length === 0"
        >
          {{
            $t('components.vehicleInspectionAndRepairManagement.action.submit')
          }}
        </AppButton>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { VehicleInspectionConfig } from '@/config/VehicleInspectionConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import InspectionItemCard from '@/composites/vehicle/shared/Inspection/InspectItemCard.vue'
import { useEndpoints } from '@/composables'
import { xMan } from '@/utils'
import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'
export default {
  name: 'VehicleInspection',
  components: {
    SlideOverRight,
    BatteryStatus,
    InspectionItemCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    vehicleProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sorId: VehicleInspectionConfig.events.sorId,
      isLoading: false,
      inspectionId: null,
      inspectionData: [],
      isPassAllClicked: false,
      sockListener: null,
      inspectionStatusTimeout: null,
      takeActionFrom: null,
    }
  },
  async created() {},
  mounted() {
    EventBus.$on(VehicleInspectionConfig.events.editingData, (data) => {
      if (this.id === `vi-${data.inspectIndex}`) {
        this.inspectionId = data.inspectionId
        this.takeActionFrom = data.takeActionFrom
        const initialModifiedData = data.parts.map((part) => {
          return {
            ...part,
            isPassClicked: false,
            isFailedClicked: false,
          }
        })

        const finalModifiedData = initialModifiedData.map((initItem) => {
          const modifiedSubPart = initItem.sub_parts.map((element) => {
            return {
              ...element,
              isChecked: false,
            }
          })
          return {
            ...initItem,
            sub_parts: modifiedSubPart,
          }
        })
        this.inspectionData = finalModifiedData
      }
    })
  },
  beforeDestroy() {
    this.removeListener()
  },
  computed: {
    titleText() {
      return this.$t(
        'components.vehicleInspectionAndRepairManagement.title.inspection'
      )
    },
    isEditing: function() {
      return !!this.primaryKey
    },
    actionButtonText: function() {
      return 'Submit'
    },
    getBatteryLevel() {
      return this?.vehicleProfile?.lock?.power_level || 0
    },
    getFailedSubpartIds() {
      const subpartIds = []
      this.inspectionData.forEach((part) => {
        part.sub_parts.map((subpart) => {
          if (subpart.isChecked) {
            subpartIds.push(subpart.id)
          }
        })
      })
      return subpartIds
    },
    getAllPassedClickedStatus() {
      if (this.isPassAllClicked) {
        return true
      }
      if (
        this.inspectionData.filter((part) => part.isPassClicked).length ===
        this.inspectionData.length
      ) {
        return true
      }
      return false
    },
  },
  watch: {},
  methods: {
    closeModal() {
      dispatchEvent(new Event(VehicleInspectionConfig.events.sorClose(this.id)))
    },
    goBack() {
      this.closeModal()
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrlV1 +
        SocketConfig.channels.userInspection +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.inspection_submitted') return

      let inspectionId = payload.data.id
      if (!inspectionId) return
      if (this.inspectionId !== inspectionId) return

      console.log(
        `Incoming data for inspection ${inspectionId} : data -->`,
        payload.data
      )
      if ('status' in payload.data) {
        let status = payload.data.status

        if (status) {
          clearTimeout(this.inspectionStatusTimeout)
          this.lastTask(status)
        }
      }
    },
    handlePassAll() {
      this.startListener()
      this.isPassAllClicked = true
      this.inspectionData = this.inspectionData.map((part) => {
        return {
          ...part,
          isPassClicked: true,
          isFailedClicked: false,
        }
      })
      this.isLoading = true
      this.handleInspectionSubmit()
    },
    async handleSubmit() {
      this.startListener()
      if (this.getFailedSubpartIds.length > 0) {
        this.inspectionData = this.inspectionData.map((part) => {
          if (!part.isPassClicked && !part.isFailedClicked) {
            return {
              ...part,
              isPassClicked: true,
              isFailedClicked: false,
            }
          }
          if (part.isFailedClicked) {
            if (!part.sub_parts.find((sb) => sb.isChecked)) {
              return {
                ...part,
                isPassClicked: true,
                isFailedClicked: false,
              }
            }
          }
          return { ...part }
        })
        const requests = []
        this.getFailedSubpartIds.forEach((subpartId) => {
          const url = useEndpoints.operationPrimer.inspectionAction.updateSubpart(
            subpartId
          )
          const data = new xMan({ status: 'REPAIR_REQUIRED' }).toFormData()
          const subpartReq = this.$http({
            url: url,
            method: 'PATCH',
            data: data,
          })
          requests.push(subpartReq)
        })
        this.isLoading = true
        await this.$http
          .all(requests)
          .then(
            this.$http.spread(() => {
              this.handleInspectionSubmit()
            })
          )
          .catch((err) => {
            console.error('Error occurred!', err)
            this.$notify({
              group: 'bottomLeft',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.detail,
            })
          })
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Caution!',
            text: 'Please select fail reason to take the action',
          },
          3000
        )
      }
    },
    async handleInspectionSubmit() {
      await this.$http
        .patch(
          useEndpoints.operationPrimer.inspectionAction.submit(
            this.inspectionId
          )
        )
        .then(() => {
          this.inspectionStatusTimeout = setTimeout(() => {
            if (this.$refs.slideOverRightInspection.show) {
              this.$http
                .get(
                  useEndpoints.operationPrimer.inspectionAction.status(
                    this.inspectionId
                  )
                )
                .then((res) => {
                  this.lastTask(res.data.status)
                })
                .finally(() => {
                  this.isLoading = false
                  this.isPassAllClicked = false
                  clearTimeout(this.inspectionStatusTimeout)
                })
            }
          }, 10000)
        })
    },
    changePassFailClickStatus(event, index) {
      if (event === 'pass') {
        this.inspectionData[index].isPassClicked = true
        this.inspectionData[index].isFailedClicked = false
        this.inspectionData[index].sub_parts = this.inspectionData[
          index
        ].sub_parts.map((subpart) => {
          return {
            ...subpart,
            isChecked: false,
          }
        })
      }
      if (event === 'fail') {
        this.inspectionData[index].isPassClicked = false
        this.inspectionData[index].isFailedClicked = true
      }
    },
    handleSubpart(parentIndex, data) {
      this.inspectionData[parentIndex].sub_parts[data.childIndex].isChecked =
        data.value
    },
    lastTask(status) {
      this.removeListener()
      if (status === 'REPAIR_REQUIRED') {
        this.$notify(
          {
            group: 'bottomRight',
            type: 'success',
            title: `Submitted for ${this.vehicleProfile.name}`,
            text: `Successful and now it needs repairment`,
          },
          5000
        )
      }
      if (status === 'COMPLETED') {
        this.$notify(
          {
            group: 'bottomRight',
            type: 'success',
            title: `Submitted for ${this.vehicleProfile.name}`,
            text: 'Successful and now you can start inspection.',
          },
          5000
        )
      }
      this.isLoading = false
      this.isPassAllClicked = false
      this.closeModal()
      if (this.takeActionFrom === 'vehicle-list') {
        this.$store.dispatch('fsTable/fetchData')
      }
      if (this.takeActionFrom === 'vehicle-details') {
        this.$store.dispatch('vehicleDetails/fetchData', {
          type: 'details',
          primaryKey: `${this.vehicleProfile.id}`,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply rounded-lg;
}
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
