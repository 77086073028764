var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('section',[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleName'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleName'
            ),"rules":"required","placeholder":"e.g. Otoride 101"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleQRCode'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleQRCode'
            ),"rules":"required","placeholder":"e.g. 1000100001"},model:{value:(_vm.form.qr_code),callback:function ($$v) {_vm.$set(_vm.form, "qr_code", $$v)},expression:"form.qr_code"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleManufacturer'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleManufacturer'
            ),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleManufacturer'
            ),"rules":"required","text-attribute":"name","value-attribute":"id","options":_vm.vehicleManufacturers,"hide-search-box":""},model:{value:(_vm.form.manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer", $$v)},expression:"form.manufacturer"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleType'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleType'
            ),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleType'
            ),"rules":"required","text-attribute":"text","value-attribute":"value","options":_vm.vehicleTypes,"hide-search-box":""},model:{value:(_vm.form.bike_category),callback:function ($$v) {_vm.$set(_vm.form, "bike_category", $$v)},expression:"form.bike_category"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.assignedFleet'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.assignedFleet'
            ),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.assignedFleet'
            ),"text-attribute":"name","value-attribute":"id","options":_vm.fleets,"hide-search-box":""},model:{value:(_vm.form.fleet),callback:function ($$v) {_vm.$set(_vm.form, "fleet", $$v)},expression:"form.fleet"}}),_c('AppInput',{attrs:{"value":_vm.getSelectedFleetLocation,"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.fleetLocation'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.fleetLocation'
            ),"placeholder":"--","disabled":""}})],1),(_vm.isEditing)?_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.assignedRentStation'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.assignedRentStation'
            ),"placeholder":_vm.getRentStationPlaceholder,"disabled":_vm.getRentStationDisabledState,"text-attribute":"text","value-attribute":"value","options":_vm.getRentStationList},model:{value:(_vm.form.rent_area.id),callback:function ($$v) {_vm.$set(_vm.form.rent_area, "id", $$v)},expression:"form.rent_area.id"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.manualLockCode'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.manualLockCode'
            ),"placeholder":"e.g. 1234"},model:{value:(_vm.form.manual_lock_code),callback:function ($$v) {_vm.$set(_vm.form, "manual_lock_code", $$v)},expression:"form.manual_lock_code"}})],1):_vm._e(),_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleModel'
            ),"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.vehicleModel'
            ),"placeholder":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.placeHolder.vehicleModel'
            ),"rules":"","options":_vm.vehicleModels,"valueAttribute":"id","textAttribute":"title","hide-search-box":true},on:{"update:options":function($event){_vm.vehicleModels=$event}},model:{value:(_vm.form.organization_vehicle_model),callback:function ($$v) {_vm.$set(_vm.form, "organization_vehicle_model", $$v)},expression:"form.organization_vehicle_model"}})],1),_c('div',[_c('TCheckbox',{attrs:{"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.enableLocationSimulationMode'
            ),"wrapped":""},on:{"change":_vm.onUseLatLongField},model:{value:(_vm.form.is_under_simulation),callback:function ($$v) {_vm.$set(_vm.form, "is_under_simulation", $$v)},expression:"form.is_under_simulation"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.form.is_under_simulation)?_c('div',{ref:"latLongFieldRef",staticClass:"grid grid-cols-2 gap-4"},[_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.latitude'
                ),"label":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.latitude'
                ),"rules":"required","placeholder":"e.g. 24.4408"},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=$$v},expression:"latitude"}}),_c('AppInput',{attrs:{"type":"text","name":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.longitude'
                ),"label":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.longitude'
                ),"rules":"required","placeholder":"e.g. 90.1204"},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=$$v},expression:"longitude"}})],1):_vm._e()])],1),_c('div',[_c('TCheckbox',{attrs:{"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.addDescriptionOptional'
            ),"wrapped":""},on:{"change":_vm.onUseDescriptionField},model:{value:(_vm.useDescriptionField),callback:function ($$v) {_vm.useDescriptionField=$$v},expression:"useDescriptionField"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.useDescriptionField)?_c('AppInput',{ref:"descriptionFieldRef",attrs:{"type":"textarea","name":_vm.$t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.description'
              ),"label":_vm.$t(
                'components.vehicleManagement.addEdit.steps.vehicle.title.description'
              )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}):_vm._e()],1)],1),_c('div',[_c('TCheckbox',{attrs:{"label":_vm.$t(
              'components.vehicleManagement.addEdit.steps.vehicle.title.addChargingAdapterOptional'
            ),"wrapped":""},on:{"change":_vm.onChargingAdapterField},model:{value:(_vm.useChargingAdapterField),callback:function ($$v) {_vm.useChargingAdapterField=$$v},expression:"useChargingAdapterField"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.useChargingAdapterField)?_c('div',{staticClass:"flex items-center gap-2"},[_c('AppInput',{ref:"chargingAdapterFieldRef",attrs:{"type":"test","name":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.adapterCode'
                ),"label":_vm.$t(
                  'components.vehicleManagement.addEdit.steps.vehicle.title.adapterCode'
                ),"disabled":_vm.chargingAdapterData.id ? true : false},model:{value:(_vm.chargingAdapterData.adapter_code),callback:function ($$v) {_vm.$set(_vm.chargingAdapterData, "adapter_code", $$v)},expression:"chargingAdapterData.adapter_code"}}),(_vm.chargingAdapterData.id)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.removeChargingAdapterField(_vm.chargingAdapterData.id, true)}}},[(_vm.isChargingAdapterDeleteLoading)?_c('i',{staticClass:"fas fa-arrows-rotate animate-spin text-gray-500"}):_c('i',{staticClass:"fas fa-trash text-gray-600"})]):_vm._e()],1):_vm._e()])],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }